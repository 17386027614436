<template>
  <div class="account-list">
    <TableHeaderContainer :fields="dataFields" :styles="styleObject"></TableHeaderContainer>
    <TableRowContainer
      ref="table"
      v-if="accounts.length"
      :data="accounts"
      :dataKeys="dataKeys"
      :styles="styleObject"
      :selectedItem="selectedItem"
      :pathIdParam="'accountId'"
      @selectedElement="setSelectedAccount"
      @loadMoreItems="loadMoreItems"
    >
    </TableRowContainer>
    <template v-if="loadingItems">
      <div class="loading-items">
        <p class="loading-items__text">{{ $t("loading") }}...</p>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import TableRowContainer from "@/components/TableRowsContainer.vue";
import ClearDetailInfoFromTable from "../../../../Extend/ClearDetailInfoFromTable";
import TableHeaderContainer from "../../../../components/TableHeaderContainer.vue";

export default {
  props: {
    accountInfo: Object,
    accounts: Array,
    loadingItems: Boolean,
    accountsSearchText: String,
  },
  name: "AccountList",
  mixins: [ClearDetailInfoFromTable],
  components: {
    TableHeaderContainer,
    TableRowContainer,
  },
  data() {
    return {
      selectedCheckBox: [],
      allSelected: null,
      keyChecked: 0,
      keyTable: 0,
      companyNameFilter: null,
      statusFilter: null,
      notFound: false,
      companyNameOptions: [
        { value: "a", text: "Company" },
        { value: "b", text: "Factory" },
        { value: "c", text: "Guane" },
      ],
      statusOptions: [
        { value: "a", text: "Active" },
        { value: "b", text: "Inactive" },
      ],
      data: [],
      dataFields: [
        { key: "company_name", label: "companyName" },
        { key: "company_address", label: "companyAddress" },
      ],
      dataKeys: ["company_name", "company_address"],
      selectedItem: null,
      styleObject: {
        width: "50%",
      },
      skip: 0,
      limit: 25,
      allAccountsAreLoaded: false,
    };
  },
  async mounted() {
    this.setRouteWithAccountId(this.accounts);
  },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  watch: {
    accountInfo() {
      if (!this.accountInfo) {
        this.clearSelectedTable();
      }
    },
  },
  methods: {
    setRouteWithAccountId(data) {
      [this.selectedItem] = data;
    },
    loadMoreItems() {
      if (!this.accountsSearchText) {
        this.$emit("loadMoreItems");
      }
    },
    setSelectedAccount(data) {
      const params = { accountId: data.id };
      this.$router.replace({ params }).catch((err) => {
        if (err.name !== "NavigationDuplicated") throw err;
      });
      this.$emit("selectedAccount", {
        selectedAccount: data,
        isLoading: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-list {
  width: 100%;
  height: 100%;
  padding: 2px;
  position: absolute;
}

.loading-items {
  &__text {
    font-weight: bold;
    margin: 10px 0px;
  }
}
</style>
