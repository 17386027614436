<template>
  <div class="account-details" v-loading="loading">
    <div class="account-details__account-name" v-if="accountInformation">
      <span>{{ accountInformation.company_name }}</span>
      <div class="account-details__close" v-if="editMode">
        <button
          style="width: auto"
          @click="toggleEditMode"
          class="close-button"
          title="Close edit mode"
        >
          <i class="ion ion-close-circle-outline"></i>
        </button>
      </div>
    </div>
    <AccountDetailsSkeleton class="mt-4" v-if="!accountInformation" />
    <template v-else>
      <template v-if="!editMode">
        <div class="account-details-toolbar">
          <button
            class="account-details-toolbar__users-button"
            @click="changeView('UserManagement')"
          >
            <b-icon icon="people-fill" font-scale="1.3"></b-icon>
            Account Users
          </button>
          <button @click="toggleEditMode" class="account-details-toolbar__edit-button">
            Edit Information
          </button>
        </div>
        <OnlyReadDetails :accountInformation="accountInformation" />
        <LoginAsAUser class="mt-3" />
      </template>
      <EditAccountDetails
        v-else
        :accountInformation="accountInformation"
        @updateAccount="updateAccountHandler"
        @toggleEditMode="toggleEditMode"
        @deleteAccount="deleteAccount"
      />
    </template>
  </div>
</template>

<script>
import AccountDetailsSkeleton from "./components/AccountDetailsSkeleton.vue";
import OnlyReadDetails from "./components/OnlyReadDetails.vue";
import LoginAsAUser from "./components/LoginAsAUser.vue";
import EditAccountDetails from "./components/EditAccount/EditAccountDetails.vue";

export default {
  name: "AccountDetails",
  components: {
    AccountDetailsSkeleton,
    OnlyReadDetails,
    LoginAsAUser,
    EditAccountDetails,
  },
  data() {
    return {
      loading: false,
      accountInformation: null,
      editMode: false,
    };
  },
  async created() {
    this.loading = true;
    this.accountInformation = await this.getAccountById(this.$route.params.accountId);
    this.loading = false;
  },
  methods: {
    async getAccountById(id) {
      if (this.$options.filters.checkPermission("get:account")) {
        const response = await this.$store.dispatch("account/getAccountById", id);
        return response;
      }
      return null;
    },
    async deleteAccount() {
      this.loading = true;
      await this.$store.dispatch("account/deleteAccount", {
        id: this.accountInformation.id,
      });
      this.$router
        .replace({
          params: {
            accountId: null,
          },
        })
        .catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      this.$emit("deleteAccount", this.accountInformation.id);
    },
    updateAccountHandler(data) {
      this.loading = data;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async changeView(routeName) {
      await this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.account-details {
  position: relative;
  @media (width <= 800px) {
    background-color: #efefef;
  }
  &__account-name {
    text-align: left;
    font-weight: bold;
    font-size: 22px;
    padding: 10px 0px 20px 0px;
    span {
      display: inline-block;
      width: 90%;
    }
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.close-button {
  @include modals-close-button;
  padding-right: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  i {
    font-size: 28px;
  }
}

.account-details-toolbar {
  display: flex;
  justify-content: space-between;
  @include font-small-button-text;
  &__users-button {
    @include primary-button;
    height: 40px;
    width: 49%;
  }
  &__edit-button {
    @include secondary-button;
    height: 40px;
    width: 49%;
  }
}

::v-deep {
  .el-loading-mask {
    background-color: rgba(255, 255, 255, 0.7) !important;
  }
}
::v-deep .el-collapse-item__wrap {
  background-color: transparent;
}
::v-deep .el-collapse-item__header {
  background-color: transparent;
}
</style>
