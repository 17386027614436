<template>
  <div class="login-as-user">
    <a class="login-as-user__link" @click="loginWithAnotherAccount()">LOGIN WITH THIS ACCOUNT</a>
  </div>
</template>

<script>
import clearLocalStorage from "@/utils/ClearLocalStorage";
import DecodeToken from "@/utils/DecodeToken";

export default {
  name: "LoginAsAUser",
  methods: {
    async loginWithAnotherAccount() {
      const loginWithAnotherAccount = await this.swal({
        title: "Log in with this account?",
        text: "The current account will be logged out.",
        icon: "warning",
        buttons: ["Cancel", "Login"],
      });
      if (loginWithAnotherAccount) {
        this.loginWithAccountId();
      }
    },
    async loginWithAccountId() {
      this.$emit("loadingLoginAsAUser", true);
      const tokenAccount = await this.$store.dispatch(
        "login/loginWithOtherAccount",
        this.$route.params.accountId
      );
      if (tokenAccount.access_token) {
        clearLocalStorage();
        const decodedToken = DecodeToken(tokenAccount.access_token);
        this.$ls.set("portal-front-token", tokenAccount.access_token);
        await this.$store.dispatch("login/getAccountSettings", {
          access_token: tokenAccount.access_token,
          decodedToken,
        });
        const baseUrl = window.location.origin;
        const routeName = "my-loads";
        window.location.href = `${baseUrl}/${routeName}`;
      }
      this.$emit("loadingLoginAsAUser", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-as-user {
  display: flex;
  justify-content: flex-start;
  @include font-small-button-text;
  &__link {
    @include font-button-text;
    cursor: pointer;
    color: $color-secondary-button-text;
    outline: 0;
    padding: 5px 1%;
    width: auto;
    font-weight: 400;
    text-decoration: underline;
    i {
      font-size: 16px;
    }
  }
}
</style>
