<template>
  <div class="details-read">
    <StatementsModal />
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.accountIds") }}
      </label>
      <p class="details-group__text">{{ accountInformation.account_name }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyName") }}</label>
      <p class="details-group__text">{{ accountInformation.company_name }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.companyAddress") }}</label>
      <p class="details-group__text">{{ companyAddress }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">{{ $t("accountManagement.phoneNumber") }}</label>
      <p class="details-group__text">{{ accountInformation.phone }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label">
        {{ $t("accountManagement.website") }}
      </label>
      <p class="details-group__text">{{ accountInformation.website }}</p>
    </div>
    <div class="details-group">
      <label class="details-group__label"> Country </label>
      <p class="details-group__text">{{ accountInformation.country_code }}</p>
    </div>
    <hr />
    <div class="details-group account-abilities">
      <label class="details-group__label"> This account: </label>
      <div v-for="(item, index) in accountAbilities" :key="index">
        <p v-if="item.value" class="details-group__text details-group__text--ability">
          - {{ item.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StatementsModal from "./UpdateStatementsModal.vue";

export default {
  name: "OnlyReadDetails",
  props: {
    accountInformation: Object,
  },
  components: {
    StatementsModal,
  },
  data() {
    return {
      accountAbilities: [],
    };
  },
  created() {
    this.setAccountAbilities();
  },
  computed: {
    companyAddress() {
      /* eslint-disable */
      const address = `${this.accountInformation.company_address} - ${this.accountInformation.city}, ${this.accountInformation.state_code}, ${this.accountInformation.zip_code}`;
      return address;
    },
  },
  methods: {
    setAccountAbilities() {
      this.accountAbilities = [
        {
          name: "Is branded",
          value: this.accountInformation.is_branded,
        },
        {
          name: "Can see the shipping cost",
          value: this.accountInformation.show_cost,
        },
        {
          name: "Can dispatch loads",
          value: this.accountInformation.can_dispatch,
        },
        {
          name: "Can quote with LTL-Parcel",
          value: this.accountInformation.can_dispatch_ltl,
        },
        {
          name: "Can quote with FTL",
          value: this.accountInformation.can_dispatch_ftl,
        },
        {
          name: "Can quote with Expedited",
          value: this.accountInformation.can_dispatch_exp,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.details-read {
  position: relative;
  text-align: left;
  padding-top: 25px;
  }
.details-group {
  margin-top: 15px;
  &__label {
    @include font-standart-text;
    font-weight: bold;
    margin-bottom: 4px;
  }
  &__text {
    font-size: 14px;
    margin: 0px;
    font-weight: 400;
  }
}
</style>
