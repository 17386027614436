<template>
  <div class="account-management">
    <template v-if="noAccountsFound">
      <div class="account-management__no-accounts mt-5">
        <NotFoundIcon />
        <p>No accounts found.</p>
      </div>
    </template>
    <div v-else-if="!noAccountsFound" v-loading="loading">
      <div
        class="account-management__header"
        :class="screenWidth < 800 ? 'account-management__header--responsive' : ''"
      >
        <div class="account-management__searcher-container">
          <input
            type="text"
            class="account-management__searcher"
            v-model="accountsSearchText"
            placeholder="Search account..."
            @input="accountsDebouncer()"
          />
          <div class="account-search-input__spinner" v-if="searchLoading">
            <div class="account-search-input__spinner-icon"></div>
          </div>
          <div
            @click="(accountsSearchText = ''), intializeAccountsData()"
            class="account-search-input__delete-button"
            v-if="!searchLoading && accountsSearchText !== ''"
          >
            <i class="ion ion-close-outline"></i>
          </div>
        </div>
        <button @click="changeView('CreateNewAccount')" class="account-management__add-button">
          New Account
        </button>
      </div>
      <div class="account-management--mobile" v-if="screenWidth && screenWidth <= 800">
        <RowCollapse
          v-if="foundAccount"
          @disableLoading="disableLoading"
          @loadMoreItems="getMoreAccounts"
          @selectedAccount="handleSelectedAccount"
          :data="filteredAccounts"
          :allAccountsAreLoaded="allAccountsAreLoaded"
          :accountsSearchText="accountsSearchText"
        >
          <template #collapsable-detail>
            <AccountDetails
              @disableLoading="disableLoading"
              @deleteAccount="deleteAccount"
              @updateAccount="remountAccountDetails += 1"
              @loadingLoginAsAUser="loadingLoginAsAUser"
              :accounts="accounts"
              :accountInfo="accountInfo"
              :selectedAccountCard="selectedAccountCard"
              :loadingAccount="loading"
            />
          </template>
          <template #loading-container>
            <div class="loading-items" v-show="loadingMoreItems">
              <p class="loading-items__text">{{ $t("loading") }}...</p>
            </div>
          </template>
        </RowCollapse>
      </div>
      <div class="account-management__content" id="account-content" v-else-if="screenWidth > 800">
        <div class="account-management__left" :class="accountInfo ? 'padding-left' : ''">
          <AccountList
            v-if="foundAccount"
            :accounts="filteredAccounts"
            :accountInfo="accountInfo"
            :loadMoreItemsFunction="getMoreAccounts"
            :loadingItems="loadingMoreItems"
            :accountsSearchText="accountsSearchText"
            :key="renderAccountList"
            @selectedAccount="handleSelectedAccount"
            @activeRow="handleActiveRow"
            @loadMoreItems="getMoreAccounts"
          />
        </div>
        <div class="account-management__right" v-show="accountInfo">
          <AccountDetails
            v-if="accounts && selectedAccountCard"
            @disableLoading="disableLoading"
            @deleteAccount="deleteAccount"
            @updateAccount="remountAccountDetails += 1"
            @loadingLoginAsAUser="loadingLoginAsAUser"
            :accounts="accounts"
            :accountInfo="accountInfo"
            :selectedAccountCard="selectedAccountCard"
            :loadingAccount="loading"
            :showIdDetail="true"
            :key="remountAccountDetails"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable function-paren-newline */
import makeDivScrollable from "@/Extend/makeDivScrollable";
import { debounce } from "@/utils/Debounce";
import AccountList from "./AccountList.vue";
import AccountDetails from "./AccountDetails.vue";
import RowCollapse from "../../../../components/RowCollapse.vue";
import onReSize from "../../../../Extend/onResize";
import NotFoundIcon from "../../../../components/notFound.vue";

export default {
  name: "AccountsContainer",
  components: {
    AccountList,
    AccountDetails,
    RowCollapse,
    NotFoundIcon,
  },
  mixins: [makeDivScrollable, onReSize],
  data() {
    return {
      name: "",
      accountInfo: {},
      loading: true,
      loadingMoreItems: false,
      scrollLoading: false,
      skip: 0,
      limit: 25,
      allAccountsAreLoaded: false,
      accounts: [],
      selectedAccountCard: null,
      accountsSearchText: "",
      filteredAccounts: [],
      noAccountsFound: false,
      renderAccountList: 0,
      accountsDebouncer: null,
      foundAccount: null,
      searchLoading: false,
      remountAccountDetails: 0,
    };
  },
  created() {
    this.accountsDebouncer = debounce(() => this.searchText(), 300);
    this.intializeAccountsData();
  },
  watch: {
    accountInfo() {
      this.$store.commit("account/setAccountNameUserView", this.accountInfo.company_name);
    },
  },
  methods: {
    loadingLoginAsAUser(value) {
      this.loading = value;
    },
    async getAccounts(/* data */) {
      if (this.$options.filters.checkPermission("get:accounts")) {
        if (this.allAccountsAreLoaded) {
          return [];
        }
        const data = {
          skip: this.skip,
          limit: this.limit,
        };
        this.loadingMoreItems = true;
        const response = await this.$store.dispatch("account/getAllAccounts", data);
        this.skip += 25;
        this.allAccountsAreLoaded = response.length === 0;
        this.loadingMoreItems = false;
        return response;
      }
      return null;
    },
    async searchAccounts(search) {
      const accountsResult = await this.$store.dispatch("account/getSearchAccounts", search);
      return accountsResult;
    },
    deleteAccount(accountId) {
      this.$store.commit("account/deleteAccount", { id: accountId });
      this.intializeAccountsData();
      this.renderAccountList += 1;
    },
    async intializeAccountsData() {
      let accounts = this.$store.getters["account/getAccounts"];
      if (accounts.length) {
        this.skip = Math.floor(accounts.length / this.limit) * this.limit;
        if (accounts.length % this.limit === 0) {
          this.allAccountsAreLoaded = false;
        } else {
          this.allAccountsAreLoaded = true;
        }
      } else {
        accounts = await this.getAccounts({
          skip: this.skip,
          limit: this.limit,
        });
      }
      this.accounts = accounts.map((item) => ({
        ...item,
        is_active: !item.is_active ? "Inactive" : "Active",
      }));
      this.filteredAccounts = [...this.accounts];
      [this.foundAccount] = this.accounts;
      this.disableLoading();
      this.checkIfThereAreAccounts();
    },
    checkIfThereAreAccounts() {
      if (!this.accounts.length) {
        this.noAccountsFound = true;
      }
    },
    async searchText() {
      if (!this.accountsSearchText || this.accountsSearchText.length < 3) {
        this.filteredAccounts = this.$store.getters["account/getAccounts"];
        return;
      }
      this.searchLoading = true;
      const data = {
        search: this.accountsSearchText,
      };
      const response = await this.searchAccounts(data);
      this.filteredAccounts = response;
      this.searchLoading = false;
    },
    filterAccountsByQuery(query) {
      return this.accounts.filter((el) =>
        el.company_name.toLowerCase().includes(query.toLowerCase())
      );
    },
    async getMoreAccounts() {
      if (this.scrollLoading) {
        return;
      }
      this.scrollLoading = true;
      const response = await this.getAccounts({
        skip: this.skip,
        limit: this.limit,
      });
      this.scrollLoading = false;
      this.accounts = this.accounts.concat(response);
      this.filteredAccounts = [...this.accounts];
    },
    handleSelectedAccount(payload) {
      this.selectedAccountCard = payload.selectedAccount.id;
      this.accountInfo = payload.selectedAccount;
      this.remountAccountDetails += 1;
    },
    handleActiveRow(payload) {
      this.accountInfo = payload.isActive;
      this.loading = payload.isLoading;
    },
    disableLoading() {
      this.loading = false;
    },
    changeView(pathName) {
      if (this.$route.name !== pathName) {
        this.$router.push({
          name: pathName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-items {
  @include loading-items;
}
.account-management {
  @include splitted-view-styles;
  padding-bottom: 20px;
  margin: 0px;
  width: 100%;
  &__no-accounts {
    color: $color-primary-company;
  }
  &__header {
    width: 60%;
    padding: 20px 20px 10px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &__header--responsive {
    width: 97%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  &__searcher {
    width: 100%;
    margin-left: 5px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
    font-family: $font-family-portal;
    @include font-small-standart-text;
    letter-spacing: 0px;
    color: $color-primary-company;
  }
  &__searcher-container {
    width: 80%;
    height: 40px;
    padding: 6px;
    border-radius: 15px;
    border: 1px solid $color-border-container;
    position: relative;
  }
  &__add-button {
    @include primary-button;
    @include font-button-text;
    @extend %flex-center-center;
    height: 40px;
    padding: 5px 20px;
    width: 150px;
    font-weight: 400;
    &:disabled {
      background-color: rgba(177, 177, 177, 0.774);
      &:hover {
        background-color: rgba(177, 177, 177, 0.774);
      }
    }
  }
  &__right {
    padding-right: 10px;
    height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  &__left {
    position: relative;
    padding-left: 0px;
    min-height: 700px;
    border-right: 1px solid $color-border-container;
    padding-right: 1rem;
    height: 80vh;
  }
  &--mobile {
    @media (max-width: 800px) {
      width: 97%;
      margin: auto;
    }
  }
}
.overlay-div {
  width: 100%;
  height: 70vh;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading {
  svg,
  p {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.account {
  &-search-input__delete-button {
    cursor: pointer;
    color: #737373;
    position: absolute;
    top: 20%;
    right: 2%;
  }
  &-search-input__spinner {
    color: #737373;
    position: absolute;
    top: 25%;
    right: 2%;
  }
  &-search-input__spinner-icon {
    height: 16px;
    width: 16px;
    color: #5a5a5a;
    position: relative;
    display: inline-block;
    border: 3px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
  }
}

::v-deep {
  .nav-tabs {
    margin-left: 1.25rem;
    margin-bottom: 2rem;
  }
  .b-overlay {
    background-color: #ffffff !important;
    opacity: 0.8 !important;
  }
}
</style>
