<template>
  <div class="account-details-skeleton">
    <div class="md:col-6 mb-3">
      <Skeleton width="200px" height="25px" class="mb-2"></Skeleton>
    </div>
    <div>
      <div class="toolbar-skeleton md:col-6 mb-3">
        <Skeleton width="40%" height="40px" class="mb-2"></Skeleton>
        <Skeleton width="40%" height="40px" class="mb-2"></Skeleton>
      </div>
      <div class="md:col-6">
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
        <Skeleton width="150px" class="mb-2"></Skeleton>
        <Skeleton class="mb-3"></Skeleton>
      </div>
      <hr />
      <div class="md:col-6">
        <Skeleton width="150px" class="mb-3"></Skeleton>
        <Skeleton width="200px" class="mb-2"></Skeleton>
        <Skeleton width="200px" class="mb-2"></Skeleton>
        <Skeleton width="200px" class="mb-2"></Skeleton>
        <Skeleton width="200px" class="mb-2"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  name: "AccountDetailsSkeleton",
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
.toolbar-skeleton {
  display: flex;
  justify-content: space-evenly;
}

::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
